<template>
  <div class="container">
    <header class="major">
      <h2>Kontaktformular</h2>
    </header>
    <p>Nehmen Sie mit uns Kontakt auf!</p>

    <form @submit.prevent="send">
      <input type="hidden" name="form-name" value="contact" />
      <div>
        <input v-model="name" type="text" id="name" placeholder="Name" />
        <input
            v-model="phone"
            type="tel"
            id="phone"
            placeholder="Telefonnummer"
        />
        <textarea
            v-model="message"
            id="message"
            rows="5"
            placeholder="Ihre Nachricht"
        ></textarea>
      </div>
      <div class="buttons">
        <p v-if="success" class="success result-box">
          Ihre Nachricht wurde erfolgreich übermittelt. Wir melden uns umgehend
          bei Ihnen zurück.
        </p>
        <p v-if="failure" class="failure result-box">
          Beim Übermitteln der Nachricht ist ein Fehler aufgetreten.<br />
          Bitte versuchen Sie es später erneut oder melden Sie sich telefonisch
          unter 0178-1429505.
        </p>
        <ul class="actions special">
          <li>
            <input
                @click="resetForm"
                class="wide"
                type="reset"
                value="Löschen"
            />
          </li>
          <li>
            <input
                class="wide primary"
                type="submit"
                value="Abschicken"
                :disabled="success"
            />
          </li>
        </ul>
        <p>Alle Daten werden verschlüsselt übertragen.</p>
      </div>
    </form>

    <p>
      Oder schreiben Sie uns per Mail an <a href="mailto:info@jennyundjules.de">info@jennyundjules.de</a><br>
      Oder schreiben Sie uns über Google: <a href="https://maps.app.goo.gl/qeqrrPuJtHgEk8ty9">Jenny und Jules
      Hochzeitsmusik</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      name: "",
      phone: "",
      message: "",
      success: false,
      failure: false
    };
  },
  components: {},
  methods: {
    resetForm: function() {
      this.name = "";
      this.phone = "";
      this.message = "";
      this.success = false;
      this.failure = false;
    },
    send: function() {
      const body = {
        "form-name": "contact",
        name: this.name,
        phone: this.phone,
        message: this.message
      };

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(body).toString()
      })
          .then((result) => {
            if (result.ok) {
              this.success = true;
              console.log("Form successfully submitted.");
            } else {
              this.failure = true;
              console.error(
                  "Form submission failed",
                  result.status,
                  result.statusText
              );
            }
          })
          .catch((error) => {
            alert(error);
          });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: white;
  width: unset;
  max-width: unset;
  padding: 2em 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  margin-bottom: 7px;
}

.result-box {
  padding: 1em !important;
}

.success {
  color: #009d5a;
}

.failure {
  padding: 0.25em;
  color: #d40000;
}

span.failure span.icon {
  margin-right: 0.25em;
}

button {
  padding: 14px;
  border: none;
  display: flex;
  align-items: center;

  .material-icons {
    font-size: 18px;
    margin-left: 7px;
  }
}

span {
  display: flex;
  flex-direction: row;
  align-items: center;

  .material-icons {
    font-size: 14px;
  }
}

form input[type="text"],
form input[type="tel"],
form textarea {
  margin-bottom: 1em;
}
</style>
