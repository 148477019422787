<template>
  <div id="app">
    <Old />
  </div>
</template>

<script>
import Old from "./components/Old";

export default {
  name: "App",
  components: {
    Old
  }
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Palatino Linotype";
  src: url("assets/fonts/pala.ttf");
}

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,400,400italic");

</style>
